import { champName, champPath } from "./Static";
import styles from "./Champ.module.css";

const Image = ({ id, type }) => {
  const name = champPath(id);
  const paths = {
    champ28: "champx",
    champ32b: "champx",
    champ90: "champ",
  };
  const sizes = {
    champ28: 26,
    champ32b: 32,
    champ90: 90,
  };
  const path = paths[type];
  const size = sizes[type];

  return (
    <img
      className={styles[type]}
      src={`https://cdn5.runes.lol/${path}${size}/${name}.webp`}
      srcSet={`https://cdn5.runes.lol/${path}${size}/${name}.webp ${size}w,
               https://cdn5.runes.lol/${path}${size * 2}/${name}.webp ${
        size * 2
      }w,
               https://cdn5.runes.lol/${path}${size * 3}/${name}.webp ${
        size * 3
      }w,`}
      alt={champName(id)}
    />
  );
};

export default Image;
