import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Menu.module.css'

const Menu = () => {
  const [active,setActive] = useState(false)
  return (
    <>
    <div className={styles[active? 'active' : 'inactive']} onClick={() => setActive(!active)}>
      <div className={styles.menuicon}>
        <span className={styles.lineleft}></span>
        <span className={styles.line}></span>
        <span className={styles.lineright}></span>
      </div>					
    </div>
    <div className={styles[active? 'show' : 'hide']} onClick={() => setActive(false)}>
    <div className={styles.nav}>
		<div className={styles.content}>
			<ul className={styles.list}>
        <li><Link to="/">LOL RUNES</Link></li>
				<li><a href="https://lolalytics.com/lol/tierlist/">LOL TIERLIST</a></li>
				<li><a href="https://pros.lol">PROBUILDS</a></li>
			</ul>
		</div>
	</div>
  </div>

    </>
  )
}

export default Menu
