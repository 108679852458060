import Wrapper from './Wrapper'
import { BrowserRouter as Router } from 'react-router-dom'
import MyRoutes from './Route'
import './App.module.css'

const App = () => {
  return (
    <Router basename="/">
      <Wrapper><MyRoutes /></Wrapper>
    </Router>
  )
}

export default App
