import { useState,useEffect } from 'react'

function useWidth() {
  const calcBreakPoint = () => {
    const w = window.innerWidth
    if (w>1309) return 'xlarge'
    if (w>1023) return 'large'
    if (w>767) return 'medium'
    if (w>639) return 'small'
    return 'xsmall'
  }

  const [width,setWidth] = useState(calcBreakPoint())

  useEffect(() => {
    const listener = e => {
      const w = calcBreakPoint()
      if (w !== width) setWidth(w)
    }
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener, true)
    }
  }, [width])
  return width
}

export default useWidth
