import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../components/misc/Loading";
const Front = lazy(() => import("../pages/Front"));
const RunePage = lazy(() => import("../pages/RunePage"));

const MyRoutes = () => {
  return (
    <Routes>
      <Route
        path="/champion/:champion/runes/"
        element={
          <Suspense fallback={<Loading />}>
            <RunePage />
          </Suspense>
        }
      />
      <Route
        index
        element={
          <Suspense fallback={<Loading />}>
            <Front />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default MyRoutes;
