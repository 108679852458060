import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { champPath, champName, ids } from "../misc/Static";
import { Link } from "react-router-dom";
import Champ from "../misc/Champ";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useSearchParams from "../hooks/useSearchParams";
import useWidth from "../hooks/useWidth";
import styles from "./SearchBar.module.css";

export const SearchBar = () => {
  let navigate = useNavigate();
  const width = useWidth();
  const [value, setValue] = useState("");
  const [results, setResults] = useState(false);
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  let { cid, mode, tier, vs } = useSearchParams();

  const handleChange = (e) => {
    setValue(e.target.value);
    let r,
      p = false;
    if (e.target.value.length) {
      let searchText = e.target.value.replace(/[^a-z]/gi, "").toLowerCase();
      r = Object.keys(ids)
        .map((name) => {
          let score = -100;
          let test = name.indexOf(searchText);
          if (test !== -1) {
            score = test ? 0 : 100;
            score += searchText.length - name.length;
          }
          return [ids[name], score];
        })
        .filter((row) => row[1] > -100 && (vs > 0 || row[0] !== cid))
        .sort((a, b) => b[1] - a[1])
        .slice(0, 4);
    }
    setResults({ champs: r, pros: p });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (results.champs && results.champs.length > 0) {
        navigate(`/champion/${champPath(results.champs[0][0])}/runes/`);
        setResults(false);
        setValue("");
      }
    } else if (e.keyCode === 27) {
      setValue("");
    }
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const lines = clipboardData.getData("Text").split(/\r?\n/);
    const keyword = " joined the lobby";
    const players = lines
      .filter((s) => s.endsWith(keyword))
      .join(", ")
      .replaceAll(keyword, "");
    if (players.split(",").length - 1) {
      e.stopPropagation();
      e.preventDefault();
      setValue(players);
    }
  };

  useEffect(() => {
    let focusTimeOut;
    inputRef.current.addEventListener("blur", (e) => {
      focusTimeOut = setTimeout(() => {
        if (document.activeElement !== inputRef.current) {
          setResults(false);
          setValue("");
        }
      }, 300);
    });
    return () => clearTimeout(focusTimeOut);
  }, []);

  useOnClickOutside(searchRef, () => {
    setResults(false);
    setValue("");
  });

  return (
    <div ref={searchRef} className={styles.search}>
      <label className={styles.label}>
        <input
          className={styles["box" + (width === "xsmall" ? "mobile" : "")]}
          ref={inputRef}
          placeholder={"Search Runes"}
          value={value}
          autoFocus={width !== "xsmall" && width !== "small" ? true : false}
          tabIndex="0"
          autoComplete="off"
          spellCheck="false"
          onChange={handleChange}
          onKeyDown={(e) => handleKeyDown(e, tier, mode)}
          onPaste={(e) => handlePaste(e)}
        />
      </label>
      {value.length > 0 && (
        <div
          className={styles["results" + (width === "xsmall" ? "mobile" : "")]}
        >
          {results.champs &&
            results.champs.length > 0 &&
            results.champs.map((result, i) => (
              <div className={styles.row} key={i}>
                <div className={styles.fullwidth}>
                  <Link
                    key={i}
                    to={{
                      pathname: "/champion/" + champPath(result[0]) + "/runes/",
                    }}
                  >
                    <div>
                      <Champ id={result[0]} type="champ32b" />{" "}
                      {champName(result[0])} Runes
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
