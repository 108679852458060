import { useLocation, useParams } from "react-router-dom";
import { champId } from "../misc/Static";
const useSearchParams = (match) => {
  let searchParams = {};
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // const params = new URLSearchParams(window.location.search)
  const { champion } = useParams();
  searchParams.cid = champId(champion);
  searchParams.lane = params.get("lane") ? params.get("lane") : "default";
  return searchParams;
};

export default useSearchParams;
