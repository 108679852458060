import TopBar from '../components/topbar/TopBar'
import styles from './Wrapper.module.css'
const Wrapper = ({children}) => {
  return (
    <div className={styles.menu}>
      <TopBar />
      <div className={styles.content}>
        { children }
      </div>
    </div>
  )
}

export default Wrapper
