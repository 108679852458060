import React from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import SearchBar from "./SearchBar";
import useWidth from "../hooks/useWidth";

import styles from "./TopBar.module.css";

const TopBar = ({ match }) => {
  const width = useWidth();
  return (
    <div className={styles.topbar}>
      <div
        className={
          styles[
            width === "xsmall" || width === "small" || width === "medium"
              ? "narrow"
              : "wide"
          ]
        }
      >
        {(width === "xsmall" || width === "small") && <Menu />}
        <Link to="/">
          <div className={styles.logowrap}>
            <img
              src="https://cdn5.runes.lol/logo/runes35.webp"
              srcSet="https://cdn5.runes.lol/logo/runes35.webp 119w,
              https://cdn5.runes.lol/logo/runes70.webp 238w"
              width="119"
              height="35"
              alt="Runes.LoL"
            />
          </div>
        </Link>
        {width !== "xsmall" && width !== "small" && (
          <div className={styles.links}>
            <a href="https://lolalytics.com/lol/tierlist/">LOL TIERLIST</a>
            <a href="https://pros.lol/">PROBUILDS</a>
          </div>
        )}
        <SearchBar match={match} width={width} />
      </div>
    </div>
  );
};
export default TopBar;
